<template>
  <form class="form-cms mb-2">
    <div class="form-control">
      <label for="product-general-name">Nombre general del producto:</label>
      <input
        type="text"
        name="product-general-name"
        placeholder="Nombre general del producto"
      />
    </div>
    <div class="fields-flex">
      <div class="form-control">
        <label for="family-product">Familia:</label>
        <select name="family-product">
          <option value="">Seleccionar</option>
        </select>
      </div>
      <div class="form-control">
        <label for="supermarket-product">Supermercado:</label>
        <select name="supermarket-product">
          <option value="">Seleccionar</option>
        </select>
      </div>
    </div>

    <div class="fields-flex">
      <div class="form-control">
        <label for="main-product">Producto:</label>
        <select name="main-product">
          <option value="">Seleccionar</option>
        </select>
      </div>
      <div class="form-control">
        <label for="brand-product">Marca:</label>
        <select name="brand-product">
          <option value="">Seleccionar</option>
        </select>
      </div>
    </div>

    <div class="fields-flex">
      <div class="">
        <div class="form-control">
          <label for="presentation-product">Presentación:</label>
          <select name="presentation-product">
            <option value="">Seleccionar </option>
          </select>
        </div>
        <div class="image-item text-center mt-1">
          <img src="/images/emptys/sizes/540x675-empty.svg" alt="" />
          <button type="button" class="btn btn-secondary  btn-medium mt-1">
            <i class="fas fa-upload"></i>
            Subir imagen
          </button>
        </div>
      </div>

      <div class="">
        <div class="form-control">
          <label for="cant-product">Cantidad:</label>
          <input type="number" name="cant-product" placeholder="Cantidad" />
        </div>
        <div class="form-control">
          <label for="sku-product">SKU:</label>
          <input type="number" name="sku-product" placeholder="SKU" />
        </div>
        <div class="">
          <div class="form-control">
            <label for="unity-product">Unidad :</label>
            <select name="unity-product">
              <option value="">Seleccionar </option>
            </select>
          </div>
          <div class="buttons">
            <button class="btn btn-outline-primary btn-medium">
              <i class="fas fa-pen-alt"></i> Actualizar unidad
            </button>
          </div>
        </div>
        <div class="form-control mt-1">
          <label for="price-ingredient">Precio:</label>
          <div class="d-flex gap-0-5">
            <div class="price-text"><p>S/.</p></div>
            <input type="number" name="price-ingredient" placeholder="Precio" />
          </div>
        </div>
      </div>
    </div>
    <div class="buttons mt-1">
      <button class="btn btn-primary">
        <i class="fas fa-pen-alt"></i> Actualizar
      </button>
    </div>
  </form>
</template>
